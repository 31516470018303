import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

const {host, protocol} = window.location;

const SESSION_REFRESH_INTERVAL = 1 * 60 * 1000;

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: process.env.AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.COGNITO_USER_POOL_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: `.${process.env.TOP_LEVEL_DOMAIN}`,
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        // sameSite: "strict" | "lax",
        sameSite: "strict",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
    },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
        // domain: 'your_cognito_domain',
        domain: process.env.COGNITO_USER_POOL_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${protocol}//${host}`,
        redirectSignOut: `${protocol}//${host}`,
        responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

const currentConfig = Auth.configure();

export const redirectForGoogleAuth = () => {
  const config = Auth.configure();
  const {
    domain,
    redirectSignIn,
    redirectSignOut,
    responseType
  } = config.oauth;

  const clientId = config.userPoolWebClientId;
  const googleAuthUrl = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId + '&identity_provider=Google';
  window.location = googleAuthUrl;
};

setInterval(() => Auth.currentSession(), SESSION_REFRESH_INTERVAL);

// const authParams = [
//   'response_type=code'
//   `client_id=${COGNITO_USER_POOL_CLIENT_ID}`
//   `redirect_uri=${window.location.hostname}`
// ].join('&');

// export const authURL = `https://${COGNITO_USER_POOL_DOMAIN}/login?${authParams}`;
